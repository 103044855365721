<!--我感兴趣愿意免费沟通的项目-->
<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vipRecommend.detailsOfMyProjects')"/>
        <div class="main">
            <div class="text-group1">
                <h1>{{ $t('myProject.otherMembersRecommendProjectsToMe') }}</h1>
                <h2>{{ $t('myProject.recommendPerson') }}</h2>
            </div>
            <div class="text-group2">
                <h1>项目名称</h1>
                <div class="text-group2-content">
                    <span class="publish-date">1天前发布</span>
                    <span class="brief-intro over">现代都市自行车手的风格，安全性和安全性。现代都市自行车手的风格，安全性和安全性。</span>
                </div>
            </div>
            <div class="people-wrap">
                <div class="people-info fl">
                    <img alt="" class="head-icon fl" src="@/assets/images/1.png">
                    <div class="people-info-text fl">
                        <h1 class="over">Richard Pompa</h1>
                        <h2 class="over">阿里巴巴 · CEO</h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <CustomButton class="fl" type="black">立即购买</CustomButton>
                    <CustomButton class="fl" style="margin-left:13px; " type="white">取消关注</CustomButton>
                </div>
            </div>
            <!--      <MultiPerson/>-->
            <!--      <div class="reason">-->
            <!--        &lt;!&ndash; 翻译：推荐原因&ndash;&gt;-->
            <!--        <h1>{{ $t('vipRecommend.reasonsForRecommendation') }}</h1>-->
            <!--        <p class="over4">-->
            <!--          平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因</p>-->
            <!--      </div>-->

            <!--      <div v-if="type===0" class="is-btn-group">-->
            <!--        <CustomButton class="fl" type="black">愿意免费沟通</CustomButton>-->
            <!--        <CustomButton class="fl" style="margin-left:15px; " type="white-line">拒绝</CustomButton>-->
            <!--      </div>-->
            <!--      <div v-else-if="type===1" class="is-btn-group">-->
            <!--        <CustomButton type="ban">您已愿意免费沟通</CustomButton>-->
            <!--      </div>-->
            <!--      <div v-else-if="type===2" class="is-btn-group">-->
            <!--        <CustomButton type="ban">已拒绝</CustomButton>-->
            <!--      </div>-->
            <!--      <div v-else-if="type===4" class="is-btn-group">-->
            <!--        <CustomButton type="ban">发布者已取消</CustomButton>-->
            <!--      </div>-->
            <!--      <div v-else-if="type===5" class="is-btn-group">-->
            <!--        <CustomButton type="ban">您已取消</CustomButton>-->
            <!--      </div>-->


            <!--      <div v-if="type===1" class="reason">-->
            <!--        &lt;!&ndash; 翻译：拒绝原因&ndash;&gt;-->
            <!--        <h1>{{ $t('vipRecommend.reasonsForRefuse') }}</h1>-->
            <!--        <p class="over4">拒绝原因</p>-->
            <!--      </div>-->
            <!--      <div v-if="type===4||type===5" class="reason">-->
            <!--        &lt;!&ndash; 翻译：拒绝原因&ndash;&gt;-->
            <!--        <h1>{{ $t('vipRecommend.publisherCancelsReason') }}</h1>-->
            <!--        <p class="over4">发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因</p>-->
            <!--      </div>-->
            <!--预约时间-->
            <AppointmentTime :type="1" @func="getMsgFormSon" v-if="type===2"/>


            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t('vipRecommend.projectDetail') }}</h1>
                <div class="rich-text">
                    <img alt="" src="@/assets/images/rich_content.png" style="width: 695px">
                </div>
            </div>

        </div>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/AppointmentTime";
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";

    export default {
        name: "index",
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            MultiPerson,
        },
        data() {
            return {
                type: 2,//0:未选择、1：同意、2：拒绝、3：预约时间、4：发布者已取消、5：您已取消
                stateObject: {
                    isOnly: true,
                    communicateType: 0,

                },
            }
        },
        methods: {
            getMsgFormSon(data) {
                console.log(data)
            }
        }

    }
</script>

<style scoped>
    .container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        margin: 0 auto;
    }

    .text-group1 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #E5E5E5;
        padding-top: 30px;
        padding-bottom: 30px;

    }

    .text-group1 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .text-group1 > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 8px;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #E5E5E5;
        padding-top: 30px;
    }

    .text-group2-content {
        margin-top: 8px;

    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;


    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    .reason {
        margin-top: 65px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .project-detail {
        margin-top: 72px;

    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }


</style>
